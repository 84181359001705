import * as THREE from  "three"
import { readFileSync } from "fs"
import { ShaderMaterial } from "three";

// Shaders taken from three.js examples.
const fragment_shader = readFileSync(__dirname + "/sky_fragment.glsl", "utf-8")

const vertex_shader = readFileSync(__dirname + "/sky_vertex.glsl", "utf-8")

export class Sky extends THREE.Mesh {

    private _azimuth: number = 1
    private _inclination: number = 0.49
    private uniforms: { 
        "luminance": { value: number; }; 
        "turbidity": { value: number; }; 
        "rayleigh": { value: number; }; 
        "mieCoefficient": { value: number; }; 
        "mieDirectionalG": { value: number; }; 
        "sunPosition": { value: THREE.Vector3; };
    }

    constructor() {
        super()

        this.uniforms = {
            "luminance": { value: 1 },
            "turbidity": { value: 10 },
            "rayleigh": { value: 2 },
            "mieCoefficient": { value: 0.005 },
            "mieDirectionalG": { value: 0.8 },
            "sunPosition": { value: new THREE.Vector3() },
        }

        this._calculateSunPosition()

        this.material = new THREE.ShaderMaterial( {
            fragmentShader: fragment_shader,
            vertexShader: vertex_shader,
            uniforms: THREE.UniformsUtils.clone(this.uniforms),
            side: THREE.BackSide
        })

        this.geometry = new THREE.BoxGeometry(1,1,1)
    }

    get inclination(): number {
        return this._inclination
    }

    set inclination(val: number) {
        this._inclination = val
        this._calculateSunPosition()
    }

    get azimuth(): number {
        return this._azimuth
    }

    set azimuth(val: number) {
        this._azimuth = val
        this._calculateSunPosition()
    }

    private _calculateSunPosition() {
        let sunPosition = this.uniforms.sunPosition.value

        let theta = Math.PI * (this._inclination - 0.5 );
        let phi = 2 * Math.PI * (this._azimuth - 0.5 );
        sunPosition.x = Math.cos( phi );
        sunPosition.y = Math.sin( phi ) * Math.sin( theta );
        sunPosition.z = Math.sin( phi ) * Math.cos( theta );
    }
    
    dispose() {
        (<ShaderMaterial>this.material).dispose()
        this.geometry.dispose()
    }
}