import * as THREE from "three";
import * as dat from "dat.gui";
let Stats = require("stats-js");
import { Sky } from "./sky/sky";
import { ThirdPerson } from "./third_person";

class App {
    private _renderer: THREE.WebGLRenderer;
    private _scene: THREE.Scene;
    private _thirdperson: ThirdPerson;
    private _clock: THREE.Clock;
    private _stats: any;
    private _gui: any;

    constructor() {
        this._scene = new THREE.Scene();
        this._renderer = new THREE.WebGLRenderer({ antialias: true });
        this._clock = new THREE.Clock();
        this.initGUI();
    }

    public initGUI() {
        this._gui = new dat.GUI();
        this._gui.add(this, "fullscreen");
    }

    private fullscreen() {
        if (!document.fullscreenElement) {
            document.documentElement.requestFullscreen();
        } else {
            if (document.exitFullscreen) {
                document.exitFullscreen();
            }
        }
    }

    public setupCharacter() {
        this._scene.add(new THREE.AmbientLight(0x222222));
        let light = new THREE.DirectionalLight(0xffffff, 1);
        light.position.set(-25, 25, 1).normalize();
        this._scene.add(light);

        let geometry = new THREE.BoxGeometry(1, 1, 1);
        let material = new THREE.MeshBasicMaterial({ color: 0x00ff00 });
        let cube = new THREE.Mesh(geometry, material);
        this._scene.add(cube);

        this._thirdperson = new ThirdPerson(cube);
        window.addEventListener("resize", this.onWindowsResize.bind(this), false);
    }

    public createScene() {
        this._renderer.setClearColor(0xffffff);
        this._renderer.setSize(window.innerWidth, window.innerHeight);
        document.body.appendChild(this._renderer.domElement);
        this._stats = new Stats();
        this._stats.showPanel(1);
        document.body.appendChild(this._stats.dom);

        let axes = new THREE.AxesHelper(20);
        this._scene.add(axes);

        let sky = new Sky();
        sky.scale.setScalar(450000);
        this._scene.add(sky);
    }

    private _render(delta) {
        this._renderer.render(this._scene, this._thirdperson._camera);
    }

    public animate() {
        requestAnimationFrame(this.animate.bind(this));
        let delta = this._clock.getDelta();
        this._thirdperson.update(delta);
        this._stats.begin();
        this._render(delta);
        this._stats.end();
    }

    private onWindowsResize() {
        this._thirdperson._camera.aspect = window.innerWidth / window.innerHeight;
        this._thirdperson._camera.updateProjectionMatrix();
        this._renderer.setSize(window.innerWidth, window.innerHeight);
    }
}

let app = new App();
app.setupCharacter();
app.createScene();
app.animate();
