import * as THREE from "three";

export class ThirdPerson {
    public _object: THREE.Object3D
    public _camera: THREE.PerspectiveCamera
    private _speedDirection = new THREE.Vector3(0, 0, 0)

    public cameraDistance = new THREE.Vector3(0,1,5)
    public aiming: THREE.Quaternion

    /// Meters per second
    public maxSpeed = 1
    public lookSpeedX = 0.005
    public lookSpeedY = 0.005
    public enable_movement = false

    constructor(character: THREE.Object3D) {
        this._camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000)

        this._object = character
        this._camera.position.copy(this._object.position).add(this.cameraDistance)
        this._camera.lookAt(this._object.position)

        this.aiming = this._camera.quaternion

        document.addEventListener("click", this._onClick.bind(this), false)
        document.addEventListener("pointerlockchange", this._onPointerLock.bind(this), false)
        document.addEventListener("pointerlockerror", this._onPointerError.bind(this), false)
        //document.addEventListener("contextmenu", this._onContextMenu.bind(this), false)
        document.addEventListener("mousedown", this._onMouseDown.bind(this), false)
        document.addEventListener('mousemove', this._onMouseMove.bind(this), false)
        document.addEventListener('mouseup', this._onMouseUp.bind(this), false)
        document.addEventListener("wheel", this._onMouseWheel.bind(this), false)

        document.addEventListener("touchstart", this._onTouchStart.bind(this), false)
        document.addEventListener("touchend", this._onTouchEnd.bind(this), false)
        document.addEventListener("touchmove", this._onTouchMove.bind(this), false)

        window.addEventListener('keydown', this._onKeyDown.bind(this), false)
        window.addEventListener('keyup', this._onKeyUp.bind(this), false)
    }

    _onPointerLock(event: Event) {
        this.enable_movement = document.pointerLockElement === document.body
    }

    _onPointerError(event: Event) {
    }

    _onClick(event: MouseEvent) {
        if (document.pointerLockElement !== document.body) {
            document.body.requestPointerLock()
            this.enable_movement = true
        }
        else {
            document.exitPointerLock()
            this.enable_movement = false
        }
    }
    //_onContextMenu() {}

    _onMouseDown(event: MouseEvent) { }
    _onMouseMove(event: MouseEvent) {
        if (this.enable_movement) {
            this._cameraAxis(event.movementX, event.movementY)
        }
    }
    _onMouseUp(event: MouseEvent) { }

    // Paramaters are between -1 and 1 meaning respectively, -1 reversed max speed, 0 stop or 1 max speed
    _playerAxis(speed_x: number, speed_y: number) {
        let pow_x = speed_x * speed_x
        let pow_y = speed_y * speed_y
        if (pow_x + pow_y >= 2) {
            let length = Math.sqrt(pow_x + pow_y)
            this._speedDirection.x = speed_x / length
            this._speedDirection.y = speed_y / length
        } else {
            this._speedDirection.x = speed_x
            this._speedDirection.y = speed_y
        }
    }

    _cameraAxis(delta_x: number, delta_y: number) {
        /// Add Limits
        let phi = this.lookSpeedY * delta_y
        let theta = this.lookSpeedX * delta_x
        let x_offset = new THREE.Quaternion().setFromEuler(new THREE.Euler(-phi, 0, 0))
        let y_offset = new THREE.Quaternion().setFromEuler(new THREE.Euler(0, -theta, 0))
        this.aiming = this._camera.quaternion.clone().premultiply(y_offset).multiply(x_offset)
    }

    _onMouseWheel(event: WheelEvent) {
    }

    _onTouchEnd() { }
    _onTouchStart() { }
    _onTouchMove() { }

    _onKeyDown(event: KeyboardEvent) {
        console.log("pressing")
        switch (event.key) {
            case "w":
            case "ArrowUp":
                this._speedDirection.z = -1
                break;
            case "s":
            case "ArrowDown":
                this._speedDirection.z = 1
                break;
            case "a":
            case "ArrowLeft":
                this._speedDirection.x = -1
                break;
            case "d":
            case "ArrowRight":
                this._speedDirection.x = +1
                break;
        }
    }

    _onKeyUp(event: KeyboardEvent) {
        console.log("Key Up")
        // TODO: this should be reviewed to weird things when keys are pressed faster
        switch (event.key) {
            case "w":
            case "ArrowUp":
                this._speedDirection.z = 0
                break;
            case "s":
            case "ArrowDown":
                this._speedDirection.z = 0
                break;
            case "a":
            case "ArrowLeft":
                this._speedDirection.x = 0
                break;
            case "d":
            case "ArrowRight":
                this._speedDirection.x = 0
                break;
        }
    }

    public update(delta_time: number) {
        let rotation = new THREE.Quaternion(0, this._camera.quaternion.y, 0, this._camera.quaternion.w)
        rotation.normalize()
        let displacement = this._speedDirection.clone().applyQuaternion(rotation).multiplyScalar(delta_time * this.maxSpeed)
        // TODO: Apply physics to the displament
        // Something like a height map in vector form with a chunk of vectors
        // needs a worker to avoid going far away from own maps limits because a drop in frames
        this._object.position.add(displacement)
        this._camera.position.copy(this._object.position.clone().add(this.cameraDistance.clone().applyQuaternion(this.aiming)))
        this._camera.quaternion.copy(this.aiming)
    }

    public dispose() {
        //document.removeEventListener("contextmenu", this._onContextMenu.bind(this), false)
        document.removeEventListener("mousedown", this._onMouseDown.bind(this), false)
        document.removeEventListener("mousemove", this._onMouseMove.bind(this), false)
        document.removeEventListener("mouseup", this._onMouseUp.bind(this), false)
        document.removeEventListener("wheel", this._onMouseWheel.bind(this), false)

        document.removeEventListener("touchstart", this._onTouchStart.bind(this), false)
        document.removeEventListener("touchend", this._onTouchEnd.bind(this), false)
        document.removeEventListener("touchmove", this._onTouchMove.bind(this), false)

        window.removeEventListener("keydown", this._onKeyDown.bind(this), false)
        window.removeEventListener("keyup", this._onKeyUp.bind(this), false)
    }
}
